import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import '../globalStyles.css';  // Import the global styles

const PuzzlePage = () => {
    const [puzzle, setPuzzle] = useState(null);
    const [answer, setAnswer] = useState('');
    const [username, setUsername] = useState('');
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPuzzle = async () => {
            setLoading(true);
            setError(null);
            try {
                const puzzleId = 'homepage'; // Initial puzzle ID for homepage
                const docRef = doc(db, 'puzzles', puzzleId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setPuzzle(docSnap.data());
                } else {
                    throw new Error('No such document!');
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchPuzzle();
    }, []);

    const handleUsernameSubmit = async () => {
        if (!username) {
            setFeedback('Please enter a username.');
            return;
        }

        try {
            const userDocRef = doc(db, 'users', username);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                loadPuzzle(userData.currentPuzzle);
            } else {
                const initialPuzzleId = 'WU8ECfqxLvRd8uYvrQ2P'; // Replace with the actual first puzzle ID
                await setDoc(userDocRef, { currentPuzzle: initialPuzzleId });
                loadPuzzle(initialPuzzleId);
            }
        } catch (err) {
            setError(err.message);
        }
    };

    const handleAnswerSubmit = async () => {
        if (answer.toLowerCase() === puzzle.Answer.toLowerCase()) {
            setFeedback('Correct!');
            const nextPuzzleId = puzzle.nextPuzzle;
            if (nextPuzzleId) {
                await updateUserCurrentPuzzle(nextPuzzleId);
                loadPuzzle(nextPuzzleId);
            } else {
                setFeedback('Congratulations! You have completed all puzzles.');
            }
        } else {
            setFeedback('Incorrect, try again.');
        }
    };

    const updateUserCurrentPuzzle = async (puzzleId) => {
        try {
            const userDocRef = doc(db, 'users', username);
            await updateDoc(userDocRef, { currentPuzzle: puzzleId });
        } catch (err) {
            setError(err.message);
        }
    };

    const loadPuzzle = async (puzzleId) => {
        setLoading(true);
        setError(null);
        try {
            const docRef = doc(db, 'puzzles', puzzleId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setPuzzle(docSnap.data());
                setAnswer('');
                setFeedback('');
            } else {
                throw new Error('No such document!');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <p>Loading puzzle...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="content">
            {puzzle ? (
                <>
                    <h1>{puzzle.Title}</h1>
                    <p>{puzzle.Text}</p>
                    {puzzle.Image && <img src={puzzle.Image} alt="Puzzle" className="puzzle-image" />}
                    {puzzle.Audio && <audio src={puzzle.Audio} controls className="puzzle-audio" />}
                    {!puzzle.Answer ? (
                        <>
                            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Enter your username" />
                            <button onClick={handleUsernameSubmit}>Submit</button>
                        </>
                    ) : (
                        <>
                            <input type="text" value={answer} onChange={(e) => setAnswer(e.target.value)} placeholder="Enter your answer" />
                            <button onClick={handleAnswerSubmit}>Submit</button>
                        </>
                    )}
                    <p>{feedback}</p>
                </>
            ) : (
                <p>No puzzle found</p>
            )}
        </div>
    );
};

export default PuzzlePage;
