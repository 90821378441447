// src/components/AddPuzzle.js

import React, { useState } from 'react';
import { db } from '../firebase'; // Import the db instance
import { collection, addDoc } from 'firebase/firestore';

const AddPuzzle = () => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [answer, setAnswer] = useState('');
  const [image, setImage] = useState('');
  const [audio, setAudio] = useState('');
  const [nextPuzzle, setNextPuzzle] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, 'puzzles'), {
        Title: title,
        Text: text,
        Answer: answer,
        Image: image,
        Audio: audio,
        nextPuzzle: nextPuzzle,
      });
      console.log('Document written with ID: ', docRef.id);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <input
        type="text"
        placeholder="Text"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <input
        type="text"
        placeholder="Answer"
        value={answer}
        onChange={(e) => setAnswer(e.target.value)}
      />
      <input
        type="text"
        placeholder="Image URL"
        value={image}
        onChange={(e) => setImage(e.target.value)}
      />
      <input
        type="text"
        placeholder="Audio URL"
        value={audio}
        onChange={(e) => setAudio(e.target.value)}
      />
      <input
        type="text"
        placeholder="Next Puzzle ID"
        value={nextPuzzle}
        onChange={(e) => setNextPuzzle(e.target.value)}
      />
      <button type="submit">Add Puzzle</button>
    </form>
  );
};

export default AddPuzzle;
