// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import PuzzlePage from './components/PuzzlePage';
import AddPuzzle from './components/AddPuzzle';
import './App.css';

const App = () => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        return () => unsubscribe();
    }, []);

    return (
        <Router>
            <div className="app">
                <header>
                    <div className="user-status">
                        {user ? (
                            <div className="status-indicator green"></div>
                        ) : (
                            <div className="status-indicator red"></div>
                        )}
                    </div>
                </header>
                <Routes>
                    <Route path="/" element={<PuzzlePage />} />
                    <Route path="/add-puzzle" element={<AddPuzzle />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;
