import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBU4ImxelOa1Fe3HujubiI4YC4U2XR9Qt8",
  authDomain: "enigma-9da04.firebaseapp.com",
  databaseURL: "https://enigma-9da04-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "enigma-9da04",
  storageBucket: "enigma-9da04.appspot.com",
  messagingSenderId: "212721547438",
  appId: "1:212721547438:web:41a5177f9111999155d53f",
  measurementId: "G-K5K935066W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };